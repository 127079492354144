<template>
  <div class="home">
    <div class="container">
      <div class="row justify-content-md-center mb-5">
        <div class="col-12">
          <div>
            <img
              id="not-found"
              class="align-self-center"
              src="https://cvrsor.s3.us-east-1.amazonaws.com/directions.svg"
            />
            <h1 class="mt-5">404</h1>
            <h2 class="mt-5">Looks like this page is missing!</h2>
            <div class="mt-5">
              <h4>
                <router-link class="subtle" to="/"
                  >back to homepage</router-link
                >
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-family: "Queen Bold", Helvetica, Arial, sans-serif;
  font-size: 8rem;
  color: white;
}
#not-found {
  min-height: 300px;
}
</style>
