<template>
  <div class="home">
    <div class="container">
      <div class="values-list">
        <h5>
          Why you should choose me as your partner?
        </h5>

        <div class="d-flex flex-row justify-items-start">
          <div class="value-icon">
            <svg style="margin-bottom: 5px" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.16 9.74006L11.22 15.6801C11.0337 15.8648 10.7823 15.969 10.52 15.9701C10.3884 15.9708 10.2579 15.9456 10.1361 15.8958C10.0143 15.8461 9.90344 15.7727 9.81 15.6801L6.81 12.6801C6.71628 12.5871 6.64188 12.4765 6.59111 12.3546C6.54034 12.2328 6.51421 12.1021 6.51421 11.9701C6.51421 11.838 6.54034 11.7073 6.59111 11.5855C6.64188 11.4636 6.71628 11.353 6.81 11.2601C6.99737 11.0738 7.25082 10.9693 7.515 10.9693C7.77919 10.9693 8.03264 11.0738 8.22 11.2601L10.49 13.5301L15.72 8.29006C15.9074 8.10381 16.1608 7.99927 16.425 7.99927C16.6892 7.99927 16.9426 8.10381 17.13 8.29006C17.2303 8.38143 17.311 8.49225 17.3671 8.61578C17.4232 8.73932 17.4535 8.87297 17.4564 9.00862C17.4592 9.14426 17.4343 9.27906 17.3834 9.40481C17.3324 9.53056 17.2564 9.64462 17.16 9.74006V9.74006Z"
                fill="black" />
              <path
                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1V1ZM17.16 9.74L11.22 15.68C11.0337 15.8647 10.7823 15.9689 10.52 15.97C10.3884 15.9708 10.2579 15.9455 10.1361 15.8958C10.0143 15.846 9.90345 15.7727 9.81001 15.68L6.81 12.68C6.71628 12.587 6.64188 12.4764 6.59111 12.3546C6.54034 12.2327 6.51421 12.102 6.51421 11.97C6.51421 11.838 6.54034 11.7073 6.59111 11.5854C6.64188 11.4636 6.71628 11.353 6.81 11.26C6.99737 11.0737 7.25082 10.9692 7.515 10.9692C7.77919 10.9692 8.03264 11.0737 8.22001 11.26L10.49 13.53L15.72 8.29C15.9074 8.10375 16.1608 7.99921 16.425 7.99921C16.6892 7.99921 16.9426 8.10375 17.13 8.29C17.2303 8.38137 17.311 8.49219 17.3671 8.61572C17.4232 8.73926 17.4535 8.87291 17.4564 9.00856C17.4592 9.1442 17.4343 9.279 17.3834 9.40475C17.3324 9.5305 17.2564 9.64456 17.16 9.74V9.74Z"
                fill="#E6E6E6" />
            </svg>
          </div>
          <div class="value-text">
            Resilience: For any issue that my client faces, I actively engage with it and find a solution.
          </div>
        </div>

        <div class="d-flex flex-row justify-items-start">
          <div class="value-icon">
            <svg style="margin-bottom: 5px" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.16 9.74006L11.22 15.6801C11.0337 15.8648 10.7823 15.969 10.52 15.9701C10.3884 15.9708 10.2579 15.9456 10.1361 15.8958C10.0143 15.8461 9.90344 15.7727 9.81 15.6801L6.81 12.6801C6.71628 12.5871 6.64188 12.4765 6.59111 12.3546C6.54034 12.2328 6.51421 12.1021 6.51421 11.9701C6.51421 11.838 6.54034 11.7073 6.59111 11.5855C6.64188 11.4636 6.71628 11.353 6.81 11.2601C6.99737 11.0738 7.25082 10.9693 7.515 10.9693C7.77919 10.9693 8.03264 11.0738 8.22 11.2601L10.49 13.5301L15.72 8.29006C15.9074 8.10381 16.1608 7.99927 16.425 7.99927C16.6892 7.99927 16.9426 8.10381 17.13 8.29006C17.2303 8.38143 17.311 8.49225 17.3671 8.61578C17.4232 8.73932 17.4535 8.87297 17.4564 9.00862C17.4592 9.14426 17.4343 9.27906 17.3834 9.40481C17.3324 9.53056 17.2564 9.64462 17.16 9.74006V9.74006Z"
                fill="black" />
              <path
                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1V1ZM17.16 9.74L11.22 15.68C11.0337 15.8647 10.7823 15.9689 10.52 15.97C10.3884 15.9708 10.2579 15.9455 10.1361 15.8958C10.0143 15.846 9.90345 15.7727 9.81001 15.68L6.81 12.68C6.71628 12.587 6.64188 12.4764 6.59111 12.3546C6.54034 12.2327 6.51421 12.102 6.51421 11.97C6.51421 11.838 6.54034 11.7073 6.59111 11.5854C6.64188 11.4636 6.71628 11.353 6.81 11.26C6.99737 11.0737 7.25082 10.9692 7.515 10.9692C7.77919 10.9692 8.03264 11.0737 8.22001 11.26L10.49 13.53L15.72 8.29C15.9074 8.10375 16.1608 7.99921 16.425 7.99921C16.6892 7.99921 16.9426 8.10375 17.13 8.29C17.2303 8.38137 17.311 8.49219 17.3671 8.61572C17.4232 8.73926 17.4535 8.87291 17.4564 9.00856C17.4592 9.1442 17.4343 9.279 17.3834 9.40475C17.3324 9.5305 17.2564 9.64456 17.16 9.74V9.74Z"
                fill="#E6E6E6" />
            </svg>
          </div>
          <div class="value-text">
            Responsiveness: being extremely responsive and keeping all lines of
            communication readily open.
          </div>
        </div>


        <div class="d-flex flex-row justify-items-start">
          <div class="value-icon">
            <svg style="margin-bottom: 5px" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.16 9.74006L11.22 15.6801C11.0337 15.8648 10.7823 15.969 10.52 15.9701C10.3884 15.9708 10.2579 15.9456 10.1361 15.8958C10.0143 15.8461 9.90344 15.7727 9.81 15.6801L6.81 12.6801C6.71628 12.5871 6.64188 12.4765 6.59111 12.3546C6.54034 12.2328 6.51421 12.1021 6.51421 11.9701C6.51421 11.838 6.54034 11.7073 6.59111 11.5855C6.64188 11.4636 6.71628 11.353 6.81 11.2601C6.99737 11.0738 7.25082 10.9693 7.515 10.9693C7.77919 10.9693 8.03264 11.0738 8.22 11.2601L10.49 13.5301L15.72 8.29006C15.9074 8.10381 16.1608 7.99927 16.425 7.99927C16.6892 7.99927 16.9426 8.10381 17.13 8.29006C17.2303 8.38143 17.311 8.49225 17.3671 8.61578C17.4232 8.73932 17.4535 8.87297 17.4564 9.00862C17.4592 9.14426 17.4343 9.27906 17.3834 9.40481C17.3324 9.53056 17.2564 9.64462 17.16 9.74006V9.74006Z"
                fill="black" />
              <path
                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1V1ZM17.16 9.74L11.22 15.68C11.0337 15.8647 10.7823 15.9689 10.52 15.97C10.3884 15.9708 10.2579 15.9455 10.1361 15.8958C10.0143 15.846 9.90345 15.7727 9.81001 15.68L6.81 12.68C6.71628 12.587 6.64188 12.4764 6.59111 12.3546C6.54034 12.2327 6.51421 12.102 6.51421 11.97C6.51421 11.838 6.54034 11.7073 6.59111 11.5854C6.64188 11.4636 6.71628 11.353 6.81 11.26C6.99737 11.0737 7.25082 10.9692 7.515 10.9692C7.77919 10.9692 8.03264 11.0737 8.22001 11.26L10.49 13.53L15.72 8.29C15.9074 8.10375 16.1608 7.99921 16.425 7.99921C16.6892 7.99921 16.9426 8.10375 17.13 8.29C17.2303 8.38137 17.311 8.49219 17.3671 8.61572C17.4232 8.73926 17.4535 8.87291 17.4564 9.00856C17.4592 9.1442 17.4343 9.279 17.3834 9.40475C17.3324 9.5305 17.2564 9.64456 17.16 9.74V9.74Z"
                fill="#E6E6E6" />
            </svg>
          </div>
          <div class="value-text">
            I can wear many hats: I'm happy to roll up my sleeves and adapt, learn, and contribute in a diverse range of
            roles.
          </div>
        </div>

        <div class="d-flex flex-row justify-items-start">
          <div class="value-icon">
            <svg style="margin-bottom: 5px" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.16 9.74006L11.22 15.6801C11.0337 15.8648 10.7823 15.969 10.52 15.9701C10.3884 15.9708 10.2579 15.9456 10.1361 15.8958C10.0143 15.8461 9.90344 15.7727 9.81 15.6801L6.81 12.6801C6.71628 12.5871 6.64188 12.4765 6.59111 12.3546C6.54034 12.2328 6.51421 12.1021 6.51421 11.9701C6.51421 11.838 6.54034 11.7073 6.59111 11.5855C6.64188 11.4636 6.71628 11.353 6.81 11.2601C6.99737 11.0738 7.25082 10.9693 7.515 10.9693C7.77919 10.9693 8.03264 11.0738 8.22 11.2601L10.49 13.5301L15.72 8.29006C15.9074 8.10381 16.1608 7.99927 16.425 7.99927C16.6892 7.99927 16.9426 8.10381 17.13 8.29006C17.2303 8.38143 17.311 8.49225 17.3671 8.61578C17.4232 8.73932 17.4535 8.87297 17.4564 9.00862C17.4592 9.14426 17.4343 9.27906 17.3834 9.40481C17.3324 9.53056 17.2564 9.64462 17.16 9.74006V9.74006Z"
                fill="black" />
              <path
                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1V1ZM17.16 9.74L11.22 15.68C11.0337 15.8647 10.7823 15.9689 10.52 15.97C10.3884 15.9708 10.2579 15.9455 10.1361 15.8958C10.0143 15.846 9.90345 15.7727 9.81001 15.68L6.81 12.68C6.71628 12.587 6.64188 12.4764 6.59111 12.3546C6.54034 12.2327 6.51421 12.102 6.51421 11.97C6.51421 11.838 6.54034 11.7073 6.59111 11.5854C6.64188 11.4636 6.71628 11.353 6.81 11.26C6.99737 11.0737 7.25082 10.9692 7.515 10.9692C7.77919 10.9692 8.03264 11.0737 8.22001 11.26L10.49 13.53L15.72 8.29C15.9074 8.10375 16.1608 7.99921 16.425 7.99921C16.6892 7.99921 16.9426 8.10375 17.13 8.29C17.2303 8.38137 17.311 8.49219 17.3671 8.61572C17.4232 8.73926 17.4535 8.87291 17.4564 9.00856C17.4592 9.1442 17.4343 9.279 17.3834 9.40475C17.3324 9.5305 17.2564 9.64456 17.16 9.74V9.74Z"
                fill="#E6E6E6" />
            </svg>
          </div>
          <div class="value-text">
            Kindness: one of the main aspects of my life that I implement in
            every facet. Treating everyone with respect, understanding all
            situations with empathy, and genuinely wanting to improve my client's
            situation.
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyValuesPage",
  data() {
    return {};
  },
  filters: {},
  methods: {},
  mounted: function () { },
  unmounted: function () { },
};
</script>

<style lang="scss">
.values-list {
  color: white;
  font-size: 1.8rem;
}

.values-list h5 {
  text-align: left;
  margin-bottom: 2.3rem;
}

.values-list div {
  text-align: left;
  vertical-align: middle;
  margin-bottom: 10px;
}

.values-list .value-icon {
  margin-right: 16px;
}

.values-list .value-text {
  text-align: left;
}

.subtle {
  color: white;
  text-decoration: underline;
  //underline color
  text-decoration-color: #686889;
}
</style>
