<template>
  <div class="home">
    <div id="intro" class="container page-content">
      <div class="d-flex flex-column justify-content-start align-items-start">
        <div align="left">Hi, I'm Alex.</div>
        <div align="left">
          I'm a full stack software engineer and tech founder with 10 years of
          experience building reliable production applications and startup
          companies.
        </div>
      </div>
    </div>

    <div id="projects" class="container page-content">
      <div class="d-flex flex-wrap justify-content-start">
        <div class="portfolio-item" v-for="(item, index) in links" v-bind:key="item.link">
          <a v-bind:href="'/work/' + item.link" v-bind:class="[
          'portfolio-link',
          { one: index == 0 },
          { two: index == 1 },
          { three: index == 2 },
          { four: index == 3 },
          { five: index == 4 },
          { six: index == 5 },
          { seven: index == 6 },
          { eight: index == 7 },
          { nine: index == 8 },
        ]" @click.prevent="goto('/work/' + item.link)">{{ item.label }}</a>
        </div>
      </div>
    </div>

    <div id="my-values" class="container page-content">
      <div class="d-flex flex-wrap justify-content-start">
        <div class="portfolio-item">
          <router-link class="values-link" to="/my-values">My values</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixins from "../components/mixins.js";

export default {
  name: "HomePage",
  mixins: [mixins],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["links"]),
  },
  methods: {
    checkAndShow: function () {
      this.$store.dispatch("fetchFromAPI");
    },
  },
  mounted() {
    //this.checkAndShow();
  },
};
</script>

<style lang="scss">
#intro div {
  font-size: 8.5vw;
  font-weight: 700;
  padding: 10px 10px;
  margin: 0px;
  line-height: 1.2em;
  color: white;
}

// #intro {
//   margin-bottom: 20rem;
// }

.page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  -moz-osx-font-smoothing: auto;
}

.portfolio-item {
  font-size: 13.5vw;
  font-weight: 700;
  padding: 10px 10px;
  margin: 0px;
  line-height: 1em;
  color: white;
}

.portfolio-link {
  text-decoration: none;
  -webkit-transition: border-color 0.15s ease-out, color 0.15s ease-out;
  -moz-transition: border-color 0.15s ease-out, color 0.15s ease-out;
  -ms-transition: border-color 0.15s ease-out, color 0.15s ease-out;
  -o-transition: border-color 0.15s ease-out, color 0.15s ease-out;
  transition: border-color 0.15s ease-out, color 0.15s ease-out;
  color: white;
}

$blur-radius: 3px;

.portfolio-link.one:hover {
  color: $link-one;
  text-shadow: 1px 1px $blur-radius $link-one;
}

.portfolio-link.two:hover {
  color: $link-two;
  text-shadow: 1px 1px $blur-radius $link-two;
}

.portfolio-link.three:hover {
  color: $link-three;
  text-shadow: 1px 1px $blur-radius $link-three;
}

.portfolio-link.four:hover {
  color: $link-four;
  text-shadow: 1px 1px $blur-radius $link-four;
}

.portfolio-link.five:hover {
  color: $link-five;
  text-shadow: 1px 1px $blur-radius $link-five;
}

.portfolio-link.six:hover {
  color: $link-six;
  text-shadow: 1px 1px $blur-radius $link-six;
}

.portfolio-link.seven:hover {
  color: $link-seven;
  text-shadow: 1px 1px $blur-radius $link-seven;
}

.portfolio-link.eight:hover {
  color: $link-eight;
  text-shadow: 1px 1px $blur-radius $link-eight;
}

.portfolio-link.nine:hover {
  color: $link-nine;
  text-shadow: 1px 1px $blur-radius $link-nine;
}

@media (min-width: 576px) {
  .portfolio-item {
    font-size: 8vw;
  }

  #intro div {
    font-size: 6vw;
  }
}

@media (min-width: 768px) {
  .page-content {
    padding: 20px 20px;
  }

  .portfolio-item {
    font-size: 7.5vw;
  }

  #intro div {
    font-size: 5vw;
  }
}

@media (min-width: 992px) {
  .portfolio-item {
    font-size: 7vw;
  }

  #intro div {
    font-size: 4vw;
  }
}

@media (min-width: 1200px) {
  .portfolio-item {
    font-size: 6vw;
  }

  #intro div {
    font-size: 3vw;
  }
}

#projects {
  margin-top: max(calc(100vh - 66px - 376px - 220px),
      10px);
  margin-bottom: 1rem;
}

/* For larger screens */
@media (min-height: 800px) {
  #projects {
    margin-top: 7rem;
    margin-bottom: 2rem;
  }
}

@media (min-height: 1000px) {
  #projects {
    margin-top: 13rem;
    margin-bottom: 5rem;
  }
}

.values-link {
  font-size: 70%;
  text-decoration: none;
  color: #76878e;
}

.values-link:hover {
  color: #8a9899;
}

video {
  width: 100% !important;
}
</style>
