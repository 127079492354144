import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomePage.vue";
import WorkItem from "../views/WorkItem.vue";
import Resume from "../views/ResumePage.vue";
import MyValues from "../views/ValuesPage.vue";
import NotFound from "../views/404.vue";

const routes = [
  { path: "/", name: "home", component: Home },
  {
    path: "/work/",
    name: "work-home",
    component: Home,
    meta: { scrollToWork: true },
  },
  { path: "/work/:projectName", name: "work-item", component: WorkItem },
  { path: "/resume", name: "resume", component: Resume },
  { path: "/my-values", name: "values", component: MyValues },
  { path: "/:pathMatch(.*)*", name: "404", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If there's a saved scroll position (e.g., from using the browser's back button), use that
    if (savedPosition) {
      return savedPosition;
    }

    // Otherwise, scroll to the top of the page
    return { top: 0 };
  },
});

export default router;
