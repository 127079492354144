<template>
  <div class="home">
    <div class="container">
      <div
        class="row project justify-content-md-center"
        v-for="(project, index) in projectText"
        v-bind:key="index"
        v-bind:id="'line-' + index"
      >
        <div class="project-info col-lg-4 d-flex flex-column align-items-start">
          <h2
            class="project-title justify-content-start mb-4"
            v-if="index == 0"
          >
            {{ projectLabel }}
            <a target="_blank" v-bind:href="website.url" v-if="website"
              ><img
                src="https://cvrsor.s3.us-east-1.amazonaws.com/thick-link.svg"
                width="20px;"
            /></a>
          </h2>
          <h4
            v-bind:class="[
              'project-desc',
              { 'text-muted': scrollSpyOn == true && activeElement != index },
            ]"
          >
            <span v-html="project.description"></span>
          </h4>
        </div>
        <div class="col-lg-8">
          <div class="project-image">
            <video
              width="100%"
              autoplay="autoplay"
              loop
              v-if="project.hasVideo === true"
            >
              <source v-bind:src="project.mp4" type="video/mp4" />
              <source v-bind:src="project.ogv" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
            <div
              class="img-box d-flex justify-content-center align-items-start"
              v-else
            >
              <transition>
                <img
                  v-show="loadedImages[index]"
                  class="image-highlighted"
                  v-bind:src="project.image"
                  @load="onImgLoad(index)"
                />
              </transition>
              <div class="loading-ring" v-show="!loadedImages[index]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="
                    margin: auto;
                    display: block;
                    shape-rendering: auto;
                    animation-play-state: running;
                    animation-delay: 0s;
                  "
                  width="50px"
                  height="50px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    stroke-width="8"
                    stroke="#6964fd"
                    stroke-dasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    stroke-linecap="round"
                    style="animation-play-state: running; animation-delay: 0s"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;360 50 50"
                      style="animation-play-state: running; animation-delay: 0s"
                    ></animateTransform>
                  </circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="23"
                    stroke-width="8"
                    stroke="#752196"
                    stroke-dasharray="36.12831551628262 36.12831551628262"
                    stroke-dashoffset="36.12831551628262"
                    fill="none"
                    stroke-linecap="round"
                    style="animation-play-state: running; animation-delay: 0s"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;-360 50 50"
                      style="animation-play-state: running; animation-delay: 0s"
                    ></animateTransform>
                  </circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row justify-content-md-center"
        v-if="projectTech.hasTechnology === true"
      >
        <div
          class="project-technology col-12 d-flex flex-column align-items-start"
        >
          <h5>Technology</h5>
          <div
            class="tech-logos col-12 d-flex flex-row justify-content-around flex-wrap"
          >
            <img
              class="align-self-center"
              v-bind:src="image"
              v-for="(image, index) in projectTech.images"
              v-bind:key="index"
            />
          </div>
          <div class="tech">{{ projectTech.description }}</div>
        </div>
      </div>

      <div
        class="row justify-content-md-center mb-5"
        v-if="hasLinks && projectName != 'pwc'"
      >
        <div class="project-links col-12 d-flex flex-column align-items-start">
          <h5 class="mb-4">Links</h5>
          <div v-for="(link, index) in projectInfo.links" v-bind:key="index">
            <h4>
              <a
                class="subtle spaced-out"
                target="_blank"
                v-bind:href="link.url"
                >{{ link.name }}</a
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WorkItem",
  data() {
    return {
      activeElement: 0,
      scrollSpyOn: false,
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    sectionIndex: function () {
      return this.section;
    },
    projectName: function () {
      if (
        Object.prototype.hasOwnProperty.call(
          this.$route.params,
          "projectName"
        ) == true
      ) {
        return this.$route.params.projectName;
      } else {
        return null;
      }
    },
    projectLabel: function () {
      if (this.projectName == null) {
        return [];
      } else {
        return this.projects[this.projectName].label;
      }
    },
    projectText: function () {
      if (this.projectName == null) {
        return [];
      } else {
        return this.projects[this.projectName].text;
      }
    },
    projectInfo: function () {
      if (this.projectName == null) {
        return [];
      } else {
        return this.projects[this.projectName].info;
      }
    },
    hasLinks: function () {
      if (
        this.projectName != null &&
        this.projects[this.projectName].info != null &&
        this.projects[this.projectName].info.links.length == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    website: function () {
      if (
        this.projectName != null &&
        this.projects[this.projectName].info != null &&
        this.projects[this.projectName].info.links.length > 0 &&
        this.projects[this.projectName].info.links[0].name == "Website"
      ) {
        return this.projects[this.projectName].info.links[0];
      } else {
        return null;
      }
    },
    projectTech: function () {
      if (this.projectName == null) {
        return [];
      } else {
        return this.projects[this.projectName].technology;
      }
    },
    ...mapGetters(["links", "projects", "loadedImages"]),
  },
  methods: {
    onImgLoad: function (index) {
      this.$store.commit("setLoadedImageVal", { index: index, val: true });
    },
    onScroll: function () {
      let that = this;
      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      const offsetHeight =
        document.body.offsetHeight || document.documentElement.offsetHeight;
      //const scrollHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );

      let projects = document.querySelectorAll(".project");
      let projectsPositions = {};

      for (let i = 0; i < projects.length; i++) {
        projectsPositions[i] = projects[i].offsetTop;
      }

      let i = 0;
      for (i in projectsPositions) {
        const offset = window.innerHeight / 2;
        if (window.innerHeight + scrollPosition >= offsetHeight) {
          that.activeElement = projects.length - 1;
        } else if (projectsPositions[i] <= scrollPosition + offset) {
          //console.log(`checking ${i}: ${projectsPositions[i]} <= ${scrollPosition} - scrollPosition: ${scrollPosition}, offset: ${offset}`);
          that.activeElement = parseInt(i);
        } else {
          //do nothing;
        }
      }
    },
    onResize: function () {
      //check if we should turn off scroll spy
      if (window.innerWidth > 576) {
        this.scrollSpyOn = true;
      }
    },
  },
  mounted: function () {
    this.onResize();
    //this.onScroll();
    document.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  unmounted: function () {
    document.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
h2 {
  font-size: 2.5rem;
  font-family: "Queen Bold", Helvetica, Arial, sans-serif;
  color: white;
}

h4 {
  font-family: "Queen Regular", Helvetica, Arial, sans-serif;
  color: white;
}

h5 {
  font-family: "Queen Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  color: white;
}

.project-info {
  padding-left: 20px;
  padding-right: 20px;
}

.project-desc {
  text-align: left;
}

.project-image img {
  max-width: 100%;
  margin-bottom: 30px;
}

.image-muted {
  border: 1px solid grey;
}

.image-highlighted {
  border: 1px solid $main-blue;
}

.spaced-out {
  letter-spacing: 1.5px;
}

.subtle {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  // margin-bottom: 0px;
  // border-bottom: 1px dashed grey;
}

.subtle:hover {
  color: $main-blue;
  text-decoration-color: $main-blue;
}

.project {
  margin-bottom: 25px;
}

.project-technology {
  color: white;
  margin-top: 100px;
}

.project-links {
  margin-bottom: 60px;
}

.tech {
  font-family: "Queen Regular", Helvetica, Arial, sans-serif;
  margin-top: 10px;
  font-size: 1.4rem;
  margin-bottom: 100px;
  text-align: left;
}

.tech-logos {
  margin-top: 30px;
  margin-bottom: 0.5rem;
}

.tech-logos img {
  max-width: 120px;
  max-height: 70px;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.img-box {
  position: relative;
  min-height: 350px;

  img,
  svg {
    transition: all 0.3s ease;
    margin: 0;
    overflow: hidden;
  }

  img.v-enter,
  img.v-leave {
    height: 0;
    padding: 0 10px;
    opacity: 0;
  }
}

.loading {
  text-indent: -9999px;
  color: white;
}

.text-muted {
  color: rgba(80, 84, 87, 0.75) !important;
}
</style>
