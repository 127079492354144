export default {
  methods: {
    goto: function (where) {
      this.$store.commit("setLoadedImages", { loadedImages: {} });
      this.$store.commit("setExpanded", { expanded: false });
      this.$nextTick(() => {
        this.$router.push(where).catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
      });
    },
  }, // end methods
};
