<template>
  <div class="home">
    <div class="container">
      <div class="row justify-content-md-center mb-5">
        <div class="project-links col-12">
          <h5 class="mb-4 d-flex justify-content-between">
            <span>Resume</span>
            <a
              class="subtle"
              href="https://cvrsor.s3.amazonaws.com/Alex+Nikolaidis+resume.pdf"
              target="_blank"
              ><i>Download</i></a
            >
          </h5>
          <iframe
            src="https://drive.google.com/file/d/1lyb5H_V2IchWqv9XnfAV-LlX0jxAELr_/preview"
            style="width: 100%; height: 100vh"
            frameborder="0"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ResumePage",
  data() {
    return {};
  },
  filters: {},
  computed: {
    ...mapGetters(["links", "projects"]),
  },
  methods: {},
  mounted: function () {},
  unmounted: function () {},
};
</script>

<style lang="scss"></style>
