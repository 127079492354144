<template>
  <div id="app">
    <header-component />
    <router-view />
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "Aktiv Medium";
  src: url(./assets/fonts/aktiv/AktivGrotesk-Medium.eot);
  src: url(./assets/fonts/aktiv/AktivGrotesk-Medium.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/aktiv/AktivGrotesk-Medium.woff2) format("woff2"),
    url(./assets/fonts/aktiv/AktivGrotesk-Medium.woff) format("woff"),
    url(./assets/fonts/aktiv/AktivGrotesk-Medium.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Queen Bold";
  src: local("Queen Bold"),
    url(./assets/fonts/queen/Quenbach-BoldCondensed.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/queen/Quenbach-BoldCondensed.woff2) format("woff2"),
    url(./assets/fonts/queen/Quenbach-BoldCondensed.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Queen Regular";
  src: local("Queen Regular"), local("Queen-Regular"),
    url(./assets/fonts/queen/Quenbach-SemiBoldCondensed.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/queen/Quenbach-SemiBoldCondensed.woff2) format("woff2"),
    url(./assets/fonts/queen/Quenbach-SemiBoldCondensed.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: "Queen Bold", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //border: 30px solid #fff;
}

.bg {
  background-color: $bg;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
