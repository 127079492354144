import { createStore } from "vuex";

export default createStore({
  state: {
    loadedImages: {},
    expanded: false,
    links: [
      { label: "Tapely", link: "tapely", enabled: true },
      { label: "Stellarfront", link: "stellarfront", enabled: true },
      { label: "Autosolo", link: "autosolo", enabled: true },
      { label: "LucidMail", link: "lucidmail", enabled: true },
      { label: "KujiraBot", link: "kujirabot", enabled: true },
      { label: "Chainrecorder", link: "chainrecorder", enabled: true },
      { label: "CalendarInsight", link: "calendarinsight", enabled: true },
      { label: "BA", link: "ba", enabled: true },
      { label: "PwC", link: "pwc", enabled: true },
    ],
    projects: {
      tapely: {
        label: "Tapely",
        text: [
          {
            description:
              "Tapely was a music streaming service that encouraged human curation and expression through aesthetics and visuals. The platform reached over 2.1 million users and had more than 50,000 user created playlists.",
            image: "https://www.tapely.com/photos/frontpage.jpg",
          },
          {
            description:
              'I initially coded the music streaming application from scratch, raised two rounds of VC funding - a total of $550k, scaled the application from zero to 2 million users, managed a team of 5 full-time hires. Unfortunately, we had to shut down - here\'s an article Wall Street Journal did on us: <a class="subtle" href="http://on.wsj.com/2c843pe" target="_blank"><span class="spaced-out">http://on.wsj.com/2c843pe</span></a>',
            image: "https://dbpclsv0nmaf4.cloudfront.net/tapely/mixtape2.jpg",
          },
          {
            description:
              "Creators could easily combine music into playlists and fully customize the visual aspect by adding images, selecting colors and fonts, allowing for an all-enveloping full page experience.",
            image: "https://www.tapely.com/photos/editor.jpg",
          },
          {
            description:
              "Listeners could find playlists using different filters, such as music genre, mood, activity, location or even by selecting a primary color.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/tapely/search2.jpg",
          },
          {
            description:
              "The platform also offered an iOS application and a social feed mechanism where users could follow other users, like their mixtapes and get notified when their favorite creators posted something new.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/tapely/mobileapp.jpg",
          },
        ],
        info: {
          links: [
            { name: "Website", url: "https://www.tapely.com" },
            {
              name: "The Next Web",
              url: "https://thenextweb.com/news/create-discover-mixtapes-online-tapely",
            },
            {
              name: "Gizmodo",
              url: "https://gizmodo.com/start-putting-together-a-new-year-mixtape-with-tape-ly-1674834451",
            },
            { name: "Wall Street Journal", url: "http://on.wsj.com/2c843pe" },
          ],
        },
        technology: {
          hasTechnology: true,
          description:
            "Tapely used Backbone.js and Marionette.js on the front-end, Ruby on Rails on the back-end, PostgreSQL as the main database, Redis for users' social feeds and stats, Memcached as a cache store for time-consuming queries and pages, ElasticSearch for indexing and searching, Sidekiq for background jobs, Iron.io MQ as a high-performance distributed tasks queue system, Amazon S3 + Cloudfront to save and serve user uploaded assets, MaxCDN for static assets and many additional supportive services for bug tracking, log management and performance monitoring.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/javascript.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/sidekiq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/ironmq.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/elasticsearch.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/aws.svg",
          ],
        },
      },

      stellarfront: {
        label: "Stellarfront",
        text: [
          {
            description:
              "Stellarfront was a computer vision startup that provided real-world analytics to physical retailers, by installing customized Raspberry Pi computers in storefronts. Our platform provided actionable insights on visitors (gender, age, group size), engagement (viewing time, popular times), as well as conversion analytics.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/stellarfront/stellarfront.jpg",
          },
          {
            description:
              'Together with my co-founder, we developed an early prototype and got accepted at <a class="subtle" href="https://www.joinef.com" target="_blank"><span class="spaced-out">Entrepreneur First</span></a> - a leading UK startup incubator backed by Reid Hoffman (founder of LinkedIn), Greylock Capital and Founders Fund. A few months later, we had active pilot installations at 4 major UK retailers in multiple locations across London.<br><br> <i>(photo: installing our sensors at Christopher Kane\'s flagship store - Mayfair, London)</i>.',
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/stellarfront/installation.jpg",
          },
          {
            description:
              "Each sensor would stream a low-resolution feed back to AWS. A custom built model would detect visitors, extract useful features from the feed and save <u>anonymized</u> metrics.",
            hasVideo: true,
            mp4: "https://dbpclsv0nmaf4.cloudfront.net/stellarfront/camera.mp4",
            ogv: "https://dbpclsv0nmaf4.cloudfront.net/stellarfront/camera.ogv",
          },
          {
            description:
              "A dashboard provided aggregate analytics and reports. This enabled retailers and visual merchantise planners to deeply understand the profile and behaviour of visitors, as well A/B test which products improved walk-in rates for their target audience.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/stellarfront/dashboard.png",
          },
        ],
        info: {
          links: [
            { name: "Entrepreneur First", url: "https://www.joinef.com" },
          ],
        },
        technology: {
          hasTechnology: true,
          description:
            "Stellarfront used Vue.js on the front-end, Ruby on Rails on the back-end, PostgreSQL as the main database, Sidekiq workers to process and consolidate analytics in a Redis database. A serverless pipeline ingested video feeds into AWS, processed data through a pretrained AI model and stored results in DynamoDB.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vuejs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/amazon-kinesis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/tensorflow.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/python.svg",
          ],
        },
      },

      kujirabot: {
        label: "KujiraBot",
        text: [
          {
            description:
              '<a class="subtle" href="https://orca.kujira.app" target="_blank"><span class="spaced-out">Orca</span></a> by Kujira, is a platform on the Terra blockchain that enables users to place bids on <a class="subtle" href="https://www.anchorprotocol.com" target="_blank"><span class="spaced-out">Anchor</span></a> liquidations and get discounted collateral.<br><br>KujiraBot was a web3 service that tracked bids placed on Kujira and executed specific actions when their status changed.',
            image: "https://dbpclsv0nmaf4.cloudfront.net/kujirabot/website.png",
          },
          {
            description:
              "Users subscribed to the service by paying in crypto (TerraUSD - UST). Kujirabot polled Anchor and Orca contracts and tracked each user's bids. When a bid's status changed, the service performed a series of customizable actions. These actions ranged from sending SMS alerts and making calls via Twilio, to dispatching Telegram messages via a Telegram Bot, or sending requests to webhook URLs for triggering more complex workflows or automations.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/kujirabot/services.png",
          },
          {
            description:
              "KujiraBot sent real-time alerts to users when there was claimable bLuna and bEth collateral. This enabled users to take full advantage of market opportunities, for example sell the collateral immediately and pocket the profit.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/kujirabot/app.png",
          },
        ],
        info: {
          links: [{ name: "Website", url: "https://www.kujirabot.com" }],
        },
        technology: {
          hasTechnology: true,
          description:
            "KujiraBot uses Vue.js on the front-end, Node.js and Koa.js on the back-end, PostgreSQL as the main database and BullMQ as a queue system. I used Terra.js to connect to a user's TerraWallet, estimate the current gas fee and send the wallet payment request. I also used Figment.io to connect to Terra mainnet and perform the necessary blockchain data queries. The app was hosted on AWS EC2.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vuejs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/node.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/bullmq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/terrajs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/aws.svg",
          ],
        },
      },

      lucidblocks: {
        label: "LucidBlocks",
        text: [
          {
            description:
              "LucidBlocks is a web3 application that scans NFT collections and aggregates minting and sales information. Given an Erc721 token address, the app traverses the Ethereum blockchain and fetches all past transactions, related wallet addresses/ENS names for each NFT token and stores data in a PostgreSQL database.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/lucidblocks/website.png",
          },
          {
            description:
              "The application allows you to view past sales information of a specific NFT collection, current and past owners of each token, price information, top token holders as well as send notifications when a token sale occurs.<br><br>I built LucidBlocks to see first-hand the challenges of retrieving, processing and analyzing blockchain data at scale, as well as to explore different database strategies to store normalized data in the most optimal way. <br><br><i>(Note: All stats are derived from processing blockchain data. The app does not use OpenSea/Etherscan's APIs to get aggregate data)</i>",
            image: "https://dbpclsv0nmaf4.cloudfront.net/lucidblocks/index.png",
          },
        ],
        info: {
          links: [],
        },
        technology: {
          hasTechnology: true,
          description:
            "LucidBlocks used Vue.js on the front-end, Node.js + Koajs on the back-end, PostgreSQL as the main database and BullMQ as a queue system that processes multiple jobs in parallel. I used a Quicknode endpoint to access the Ethereum mainnet and a t3.large EC2 instance to run multiple concurrent workers in parallel.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vuejs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/node.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/bullmq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/aws.svg",
          ],
        },
      },

      chainrecorder: {
        label: "Chainrecorder",
        text: [
          {
            description:
              "ChainRecorder is a web3 application that provides immutable proof of existence and authenticity for digital assets by timestamping them on the Bitcoin blockchain. Users can timestamp various digital assets, including images, videos, legal documents, and email messages, ensuring their integrity and provenance.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/chainrec/chainrecorder-frontpage2.png",
          },
          {
            description:
              "As part of the ChainRecorder project, I developed a full-stack application that allows subscribers to request a timestamp of any publicly available web page and record it on the Bitcoin blockchain. Built using Rails 7, the application uses Selenium and Chrome Webdriver to navigate to the requested web page and capture a full-page screenshot. The screenshot can be optionally uploaded to the IPFS network and hashed to generate a unique identifier.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/chainrec/backend.png",
          },
          {
            description:
              "One of the application's key features is a convenient bookmarklet that users can drag to their browser's bookmarks bar. When browsing a web page they wish to timestamp, users simply click the bookmarklet, and the application automatically screenshots and timestamps the page on the Bitcoin blockchain, providing a seamless and user-friendly experience.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/chainrec/bookmarklet.png",
          },
          {
            description:
              'The engagement also included the development of <a class="subtle" href="https://zapier.com/apps/chainrecorder/integrations" target="_blank"><span class="spaced-out">a custom Zapier app</span></a> that is available in Zapier\'s public marketplace. The Zapier app includes a custom Zapier action that allows users to timestamp documents, images, and other digital assets on the Bitcoin blockchain. There is also a custom Zapier trigger that sends information to a user\'s servers when a new file has been hashed.</i>',
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/chainrec/zapier-app.png",
          },
        ],
        info: {
          links: [
            { name: "Website", url: "https://chainrecorder.com/home" },
            {
              name: "Zapier App",
              url: "https://zapier.com/apps/chainrecorder/integrations",
            },
          ],
        },
        technology: {
          hasTechnology: true,
          description:
            "Chainrecorder uses Turbo and Stimulus and Ruby on Rails 7, PostgreSQL as the main database, Sidekiq for background jobs, Selenium and Chrome Webdriver for web page screenshots. The app is hosted on DigitalOcean using Docker containers.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/turbo.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/stimulus.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/sidekiq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/selenium.svg",
          ],
        },
      },

      calendarinsight: {
        label: "CalendarInsight",
        text: [
          {
            description:
              "CalendarInsight is a property management calendar app that helps vacation rental owners view and manage reservations from a single calendar. The app synced listings' calendar and pricing on Airbnb, VRBO and other platforms, as well as automate and simplify interactions with clients. ",
            image: "https://cvrsor.s3.amazonaws.com/ownerInsight-app.png",
          },
        ],
        info: {
          links: [],
        },
        technology: {
          hasTechnology: true,
          description:
            "CalendarInsight was built with Ruby on Rails, using Sidekiq to synchronize calendars, PostgreSQL, Memcached for fragment caching and Redis. Hosted on Hetzner.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/sidekiq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/memcached.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
          ],
        },
      },

      ba: {
        label: "Betting Automation",
        text: [
          {
            description:
              "Developed a betting automation bot system for a boutique betting company. Bots simulate user browser sessions, autonomously navigate a multitude of different betting sites, and are capable of placing sport bets and completing slot games.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/bv/autobot2.png",
          },
          {
            description:
              "Bot agents are scripted in Ruby. They run unsupervised in parallel and can scale up on demand. To play each game, bots follow a series of steps that admins have previously entered using a custom descriptive language. OpenCV is used to detect game state, and Tesseract is used to perform real-time OCR and read game statistics. Bots report results to a central Command and Control dashboard in real-time.",
            image: "https://dbpclsv0nmaf4.cloudfront.net/bv/autobot1.png",
          },
        ],
        info: {
          links: [],
        },
        technology: {
          hasTechnology: true,
          description:
            "This project uses Vue.js on the front-end, Ruby on Rails on the back-end, MongoDB as the main database, RabbitMQ as a queue system, and Pusher to send real-time updates. Bot agents are scripted in Ruby and run headless using Selenium and Webdriver.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vuejs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/mongo.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rabbitmq.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/selenium.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/opencv.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/aws.svg",
          ],
        },
      },

      pwc: {
        label: "PwC",
        text: [
          {
            description:
              "I was previously employed for 3+ years at PricewaterhouseCoopers as a Senior Consultant in the Financial Services Advisory. <br><br> I was responsible for delivering several engagements in Banking (Risk Management, Capital Requirements analysis, Stress Testing), Funds & Investment (Digital Process Transformation) and Insurance (Actuarial Valuations, Workflow Automation).<br><br> A.C.A. - I am also a partly accredited Chartered Accountant but did not complete my certification - I quit PwC to build <strong>Tapely</strong>. 😌",
            image: "https://dbpclsv0nmaf4.cloudfront.net/pwc/pwc2.png",
          },
        ],
        info: {
          links: [{ name: "Website", url: "https://www.pwc.com" }],
        },
        technology: {
          hasTechnology: false,
          description: null,
          images: [],
        },
      },

      lucidmail: {
        label: "LucidMail",
        text: [
          {
            description:
              "LucidMail is an email automation side project that I built to ‘scratch my own itch’ but ended up having paying clients. The app offers a highly customized email warm-up service to build up positive sender reputation and increase deliverability of outbound email campaigns. <br><br> LucidMail uses ChatGPT to compose email copy that is highly relevant to each client’s industry and campaigns",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/lucidmail/lucidmail-frontpage.png",
          },
          {
            description:
              "Project highlight: I built an email scheduling system that sends ~30k emails per day. The system uses a robust, high-throughput message queue system & concurrent background workers and complies with complex client-defined rules (custom email sequences, scheduled intervals, max. emails per day, cadence ramp-up, minimum wait time between emails, different priorities).",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/lucidmail/lucidmail-dashboard.png",
          },
        ],
        info: {
          links: [{ name: "LucidMail", url: "https://www.lucidmail.io" }],
        },
        technology: {
          hasTechnology: true,
          description:
            "lucidmail uses Vue.js on the front-end, Ruby on Rails on the back-end, PostgreSQL as the main database, Sidekiq for background jobs, Redis to store aggregate analytics and dashboard information, OAuth + Gmail API to connect Google Workspace accounts and send emails on behalf of clients and AWS.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vuejs.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/rails.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/psqlwhite.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/redis.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/sidekiq.png",
            "https://dbpclsv0nmaf4.cloudfront.net/lucidmail/google-api.png",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/aws.svg",
          ],
        },
      },
      autosolo: {
        label: "Autosolo",
        text: [
          {
            description:
              "Autosolo is a SaaS application that helps auto bodyshops save time and focus on their core business. The app features an advanced estimation tool that simplifies the repair quoting process, enabling professionals to easily calculate repair costs and generate customer-facing quotes and invoices.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/autosolo/autosolo-homepage2.png",
          },
          {
            description:
              "Damage select module: Allows users to quickly map damage onto vehicle images, select the parts that need to be repaired and tasks that need to be performed.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/autosolo/part-selection.png",
          },
          {
            description:
              "Estimation module: The system offers customizable costing parameters for different parts and vehicle types, allowing users to quickly generate an accurate estimate of labour work-hours, repair costs, paint and materials costs.",
            image:
              "https://dbpclsv0nmaf4.cloudfront.net/autosolo/autosolo-estimation.png",
          },
        ],
        info: {
          links: [],
        },
        technology: {
          hasTechnology: true,
          description:
            "Autosolo is built using Next.js, Typescript, and tRPC. PostgreSQL is used as the main database, hosted on Supabase. Prisma's API is used to query the database in a type-safe way. NextAuth.js is used for secure authentication. Tailwind CSS framework is used together with Radix UI components. The app is hosted on Vercel and uses additional services to support its operation, such as Playwright for E2E testing, Checkly for monitoring, Inngest for scheduled background jobs, and BetterStack for logging.",
          images: [
            "https://dbpclsv0nmaf4.cloudfront.net/tech/next4.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/typescript.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/trpc2.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/tailwind2.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/supabase-logo-wordmark--dark.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/Prisma-LightLogo.svg",
            "https://dbpclsv0nmaf4.cloudfront.net/tech/vercel.svg",
          ],
        },
      },
    },
  },
  getters: {
    links: (state) => {
      return state.links.filter((link) => link.enabled);
    },
    projects: (state) => state.projects,
    loadedImages: (state) => state.loadedImages,
    expanded: (state) => state.expanded,
  },
  mutations: {
    setLoadedImages(state, payload) {
      state.loadedImages = payload.loadedImages;
    },
    setLoadedImageVal(state, payload) {
      state.loadedImages[payload.index] = payload.val;
    },
    setExpanded(state, payload) {
      state.expanded = payload.expanded;
    },
    enableOneLink(state, payload) {
      const linkLabel = payload.linkLabel;
      const itemIndex = state.links.findIndex(
        (item) => item.label === linkLabel
      );
      state.links[itemIndex].enabled = true;
    },
  },
  actions: {
    async fetchFromAPI({ commit }) {
      try {
        if (process.env.NODE_ENV == "development") {
          commit("enableOneLink", {
            linkLabel: "Autosolo",
          });
          return;
        }
        const response = await fetch(
          "https://www.cvrsor.com/.netlify/functions/show"
        );
        const data = await response.json();
        if (
          Object.prototype.hasOwnProperty.call(data, "show") &&
          data.show === true
        ) {
          commit("enableOneLink", {
            linkLabel: "Autosolo",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (process.env.NODE_ENV == "development") {
          commit("enableOneLink", {
            linkLabel: "Autosolo",
          });
        }
      }
    },
  },
  modules: {},
});
