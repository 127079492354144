<template>
  <nav class="site-header py-3">
    <div class="container d-flex justify-content-center justify-content-md-between">
      <a class="py-2 d-block d-md-none align-self-center" href="/" @click.prevent="goto('/')">
        <svg width="100" height="100" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="300" height="300" fill="#0E0E27" />
          <path
            d="M227.36 266V207.6H245.44C248.907 207.6 251.947 208.32 254.56 209.76C257.173 211.147 259.2 213.093 260.64 215.6C262.08 218.107 262.8 221.04 262.8 224.4C262.8 228.347 261.76 231.733 259.68 234.56C257.653 237.387 254.88 239.333 251.36 240.4L263.6 266H255.12L243.92 241.2H234.56V266H227.36ZM234.56 234.72H245.44C248.427 234.72 250.827 233.787 252.64 231.92C254.453 230 255.36 227.493 255.36 224.4C255.36 221.253 254.453 218.747 252.64 216.88C250.827 215.013 248.427 214.08 245.44 214.08H234.56V234.72Z"
            fill="white" />
          <path
            d="M150 266.8C146.48 266.8 143.44 266.133 140.88 264.8C138.373 263.467 136.427 261.547 135.04 259.04C133.707 256.48 133.04 253.467 133.04 250V223.6C133.04 220.08 133.707 217.067 135.04 214.56C136.427 212.053 138.373 210.133 140.88 208.8C143.44 207.467 146.48 206.8 150 206.8C153.52 206.8 156.533 207.467 159.04 208.8C161.6 210.133 163.547 212.053 164.88 214.56C166.267 217.067 166.96 220.053 166.96 223.52V250C166.96 253.467 166.267 256.48 164.88 259.04C163.547 261.547 161.6 263.467 159.04 264.8C156.533 266.133 153.52 266.8 150 266.8ZM150 260.32C153.147 260.32 155.547 259.44 157.2 257.68C158.907 255.867 159.76 253.307 159.76 250V223.6C159.76 220.293 158.907 217.76 157.2 216C155.547 214.187 153.147 213.28 150 213.28C146.907 213.28 144.507 214.187 142.8 216C141.093 217.76 140.24 220.293 140.24 223.6V250C140.24 253.307 141.093 255.867 142.8 257.68C144.507 259.44 146.907 260.32 150 260.32Z"
            fill="white" />
          <path
            d="M56.32 266.8C52.48 266.8 49.1733 266.16 46.4 264.88C43.68 263.6 41.5733 261.76 40.08 259.36C38.5867 256.96 37.8133 254.107 37.76 250.8H44.96C44.96 253.733 45.9467 256.053 47.92 257.76C49.9467 259.467 52.7467 260.32 56.32 260.32C59.68 260.32 62.2933 259.493 64.16 257.84C66.08 256.187 67.04 253.893 67.04 250.96C67.04 248.613 66.4 246.56 65.12 244.8C63.8933 243.04 62.1067 241.813 59.76 241.12L51.84 238.64C47.84 237.413 44.7467 235.36 42.56 232.48C40.4267 229.6 39.36 226.213 39.36 222.32C39.36 219.173 40.0533 216.453 41.44 214.16C42.88 211.813 44.88 210 47.44 208.72C50 207.387 53.0133 206.72 56.48 206.72C61.6 206.72 65.7067 208.16 68.8 211.04C71.8933 213.867 73.4667 217.653 73.52 222.4H66.32C66.32 219.52 65.44 217.28 63.68 215.68C61.9733 214.027 59.5467 213.2 56.4 213.2C53.3067 213.2 50.88 213.947 49.12 215.44C47.4133 216.933 46.56 219.013 46.56 221.68C46.56 224.08 47.2 226.16 48.48 227.92C49.76 229.68 51.6 230.933 54 231.68L62 234.24C65.8933 235.467 68.9067 237.547 71.04 240.48C73.1733 243.413 74.24 246.853 74.24 250.8C74.24 254 73.4933 256.8 72 259.2C70.5067 261.6 68.4 263.467 65.68 264.8C63.0133 266.133 59.8933 266.8 56.32 266.8Z"
            fill="white" />
          <path
            d="M227.36 165V106.6H245.44C248.907 106.6 251.947 107.32 254.56 108.76C257.173 110.147 259.2 112.093 260.64 114.6C262.08 117.107 262.8 120.04 262.8 123.4C262.8 127.347 261.76 130.733 259.68 133.56C257.653 136.387 254.88 138.333 251.36 139.4L263.6 165H255.12L243.92 140.2H234.56V165H227.36ZM234.56 133.72H245.44C248.427 133.72 250.827 132.787 252.64 130.92C254.453 129 255.36 126.493 255.36 123.4C255.36 120.253 254.453 117.747 252.64 115.88C250.827 114.013 248.427 113.08 245.44 113.08H234.56V133.72Z"
            fill="white" />
          <path
            d="M150 165.8C144.56 165.8 140.4 164.307 137.52 161.32C134.64 158.28 133.2 154.173 133.2 149V106.6H140.4V149C140.4 152.2 141.173 154.733 142.72 156.6C144.32 158.467 146.747 159.4 150 159.4C153.2 159.4 155.6 158.467 157.2 156.6C158.8 154.733 159.6 152.2 159.6 149V106.6H166.8V149C166.8 154.227 165.36 158.333 162.48 161.32C159.653 164.307 155.493 165.8 150 165.8Z"
            fill="white" />
          <path
            d="M56.32 165.8C52.8 165.8 49.7333 165.133 47.12 163.8C44.56 162.467 42.56 160.547 41.12 158.04C39.7333 155.48 39.04 152.467 39.04 149V122.6C39.04 119.08 39.7333 116.067 41.12 113.56C42.56 111.053 44.56 109.133 47.12 107.8C49.7333 106.467 52.8 105.8 56.32 105.8C59.84 105.8 62.88 106.493 65.44 107.88C68 109.213 69.9733 111.133 71.36 113.64C72.7467 116.147 73.44 119.133 73.44 122.6H66.24C66.24 119.293 65.36 116.76 63.6 115C61.8933 113.187 59.4667 112.28 56.32 112.28C53.1733 112.28 50.6933 113.16 48.88 114.92C47.12 116.68 46.24 119.213 46.24 122.52V149C46.24 152.307 47.12 154.867 48.88 156.68C50.6933 158.44 53.1733 159.32 56.32 159.32C59.4667 159.32 61.8933 158.44 63.6 156.68C65.36 154.867 66.24 152.307 66.24 149H73.44C73.44 152.413 72.7467 155.4 71.36 157.96C69.9733 160.467 68 162.413 65.44 163.8C62.88 165.133 59.84 165.8 56.32 165.8Z"
            fill="white" />
          <g clip-path="url(#clip0_1_25)">
            <mask id="mask0_1_25" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="20" width="66"
              height="66">
              <path d="M93 20H27V86H93V20Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1_25)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M30.7511 23.751C29.9728 24.5293 29.7335 25.6968 30.143 26.7185L50.9496 78.6377C51.3901 79.7369 52.4845 80.4307 53.6664 80.36C54.8484 80.2893 55.8524 79.4701 56.1585 78.3264L59.6208 65.3992L69.0863 74.8647C70.1604 75.9386 71.9014 75.9386 72.9756 74.8647L81.8647 65.9753C82.9389 64.9014 82.9389 63.1601 81.8647 62.0863L72.3992 52.6208L85.3264 49.1585C86.4701 48.8522 87.2894 47.8481 87.36 46.6663C87.4307 45.4844 86.7369 44.3899 85.638 43.9495L33.7186 23.1429C32.6969 22.7334 31.5294 22.9726 30.7511 23.751ZM75.9255 45.9825L66.3748 48.5403C65.4258 48.7947 64.6843 49.5358 64.43 50.4848C64.1756 51.4339 64.447 52.4467 65.1417 53.1413L76.0311 64.0308L71.0308 69.0311L60.1413 58.1417C59.4467 57.4467 58.4341 57.1756 57.4851 57.43C56.5361 57.6843 55.7947 58.4257 55.5406 59.3748L52.9826 68.9255L37.6395 30.6394L75.9255 45.9825Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1_25">
              <rect width="66" height="66" fill="white" transform="translate(27 20)" />
            </clipPath>
          </defs>
        </svg>


      </a>
      <a class="mr-auto py-2 d-none d-md-inline-block align-self-center header-link" href="/"
        @click.prevent="goto('/')">
        <span>Alex Nikolaidis</span>
      </a>
      <div class="d-flex flex-column flex-md-row">
        <a class="py-2 d-block d-md-none align-self-center" href="" @click.prevent="toggleMenu()" v-if="expanded">
          <svg id="ex" width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490"
            style="enable-background: new 0 0 490 490" xml:space="preserve">
            <polygon points="11.387,490 245,255.832 478.613,490 489.439,479.174 255.809,244.996 489.439,10.811 478.613,0 245,234.161 
              11.387,0 0.561,10.811 234.191,244.996 0.561,479.174 " fill="#6964FD" />
          </svg>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import mixins from "./mixins.js";

export default {
  name: "HeaderComponent",
  mixins: [mixins],
  data() {
    return {};
  },
  computed: {
    projectName: function () {
      if (
        Object.prototype.hasOwnProperty.call(
          this.$route.params,
          "projectName"
        ) == true
      ) {
        return this.$route.params.projectName;
      } else {
        return null;
      }
    },
    isWork: function () {
      if (this.$route.name == "work") {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters(["links", "expanded"]),
  },
  methods: {
    toggleMenu: function () {
      let that = this;
      this.$store.commit("setExpanded", { expanded: !that.expanded });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.site-header {
  background-color: rgba(14, 14, 38, 0.95);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

@media (min-height: 800px) {
  .site-header {
    margin-bottom: 1rem;
  }
}

@media (min-height: 900px) {
  .site-header {
    margin-bottom: 2rem;
  }
}

@media (min-height: 1100px) {
  .site-header {
    margin-bottom: 4rem;
  }
}

.header-link {
  font-family: "Aktiv Medium", Helvetica, Arial, sans-serif;
  font-size: 0.7777777777777778rem;
  text-transform: uppercase;
  letter-spacing: 0.15555555555555556rem;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  text-decoration: none;
}

.header-link.active {
  text-decoration: none;
  border-bottom: 1px solid white;
  line-height: 1px;
  margin-bottom: 1px;
}

.header-link-major {
  font-size: 1rem;
}

.header-link:hover,
.header-link-major:hover {
  color: $main-blue;
  text-decoration: none;
}
</style>
